import React from 'react';
import Icon from './Icon';

interface Props
{
	size?: number,
	gradient?: string[]
}

const LinkedIn = (props: Props) =>
{
	return (
		<Icon
			size={props.size || 100}
			gradient={props.gradient || ["#36D1DC", "#5B86E5"]}
		>
			<path d='M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z' />
		</Icon>
	);
};

export default LinkedIn;